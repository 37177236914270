@font-face {
    font-display: swap;
    font-family: "CoFo Sans";
    font-style: normal;
    font-weight: 400; // normal
    src: url("~@/fonts/cofo-sans/regular.woff2") format("woff2"),
        url("~@/fonts/cofo-sans/regular.woff") format("woff");
}

@font-face {
    font-display: swap;
    font-family: "CoFo Sans";
    font-style: italic;
    font-weight: 400; // normal
    src: url("~@/fonts/cofo-sans/regular-italic.woff2") format("woff2"),
        url("~@/fonts/cofo-sans/regular-italic.woff") format("woff");
}

@font-face {
    font-display: swap;
    font-family: "CoFo Sans";
    font-style: normal;
    font-weight: 700; // bold
    src: url("~@/fonts/cofo-sans/medium.woff2") format("woff2"),
        url("~@/fonts/cofo-sans/medium.woff") format("woff");
}

@font-face {
    font-display: swap;
    font-family: "CoFo Sans";
    font-style: normal;
    font-weight: 900; // black
    src: url("~@/fonts/cofo-sans/black.woff2") format("woff2"),
        url("~@/fonts/cofo-sans/black.woff") format("woff");
}
