












































@use "@/styles/variables/colors";
@use "@/styles/variables/layout";
@use "@/styles/mixins/breakpoint" as *;

.header {
    height: layout.$header-height;
    background-color: colors.$dark;

    .container {
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}

.header__title {
    display: flex;
    align-items: center;
}

.header__title-logo {
    display: flex;
    margin-right: 16px;
}

.header__title-text {
    color: colors.$white;
    font-size: 16px;
    margin-top: 4px;

    @include breakpoint(sm) {
        // Visually hidden
        position: absolute !important;
        width: 1px !important;
        height: 1px !important;
        padding: 0 !important;
        overflow: hidden !important;
        clip: rect(1px, 1px, 1px, 1px) !important;
        border: 0 !important;
    }
}
