










































@use "./styles/variables/colors";

.app {
    display: flex;
    flex-direction: column;
    min-height: 100%;

    // Themed css vars

    &--generaptor {
        --bg-color: #{colors.$generaptor-brand-bg};
        --primary-color: #{colors.$generaptor-brand};
        --primary-color-alpha: #{colors.$generaptor-brand-alpha};
        --title-color: #{colors.$generaptor-brand-dark};
    }
}

.app__header {
    position: sticky;
    top: 0;
    z-index: 999;
}

.app__content {
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
}

.app__footer {
    flex-shrink: 0;
}
