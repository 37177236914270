


























































































@use "@/styles/variables/colors";
@use "@/styles/mixins/breakpoint" as *;

.footer {
    background-color: colors.$dark;
    padding-top: 60px;
    padding-bottom: 52px;
    color: colors.$white;

    @include breakpoint(sm) {
        padding-top: 48px;
    }
}

.footer__inner {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include breakpoint(sm) {
        display: block;
    }
}

.footer__logo {
    display: flex;

    @include breakpoint(sm) {
        margin-bottom: 24px;
    }
}

.footer__tiles {
    display: flex;

    @include breakpoint(sm) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 8px;
    }
}

.footer__tile {
    &:not(:last-child) {
        margin-right: 16px;

        @include breakpoint(sm) {
            margin-right: 0;
        }
    }
}

.footer__ebala {
    display: block;
    padding-top: 24px;
    font-size: 12px;
    line-height: 1;
    color: colors.$secondary-grey-light;
}
