














































































































































































$fade-in-out-duration: 300ms;

.court-filing-page__body {
    position: relative;
}

.court-filing-page__skeleton {
    opacity: 1;
    transition: opacity $fade-in-out-duration;

    &.loading-leave-active {
        position: absolute;
        top: 0;
        z-index: 1;
        width: 100%;
        opacity: 0;
    }
}

.court-filing-page__form {
    &.loading-enter-active {
        opacity: 0;
    }

    &.loading-enter-to {
        position: relative;
        z-index: -1;
        transition: opacity $fade-in-out-duration;
        opacity: 1;
    }
}
