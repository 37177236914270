























































































































































































































































































































































































































































































































@use "@/styles/variables/colors";
@use "@/styles/mixins/breakpoint" as *;

.explainer__description {
    margin-bottom: 32px;
}

// Docs

.docs__teaser {
    margin-bottom: 16px;
    font-size: 18px;
    line-height: 1.5;
    color: colors.$secondary-grey;
}

.docs__link {
    margin-bottom: 32px;
}

.docs__steps-intro {
    margin-bottom: 32px;
}

.docs__steps {
    list-style: revert;
    padding-left: 18px;
}

.docs__steps-step {
    position: relative;
    padding-bottom: 24px;

    &:last-child {
        padding-bottom: 0;
    }
}

.docs__steps-step:not(:last-child) {
    margin-bottom: 32px;

    &::after {
        content: "";
        position: absolute;
        left: -18px;
        bottom: 0;
        width: calc(100% + 18px);
        height: 1px;
        background-color: colors.$secondary-grey-light;
    }
}

.docs__steps-step-headline,
.docs__steps-step::marker {
    font-size: 18px;
    line-height: 1.45;
    font-weight: 700;
}

.docs__steps-step-headline {
    margin-bottom: 4px;
}

.docs__steps-step-bullets {
    list-style: disc;
    padding-left: 16px;
    margin-bottom: 24px;

    &--margin-small {
        margin-bottom: 16px;
    }
}

.docs__steps-step-bullets-item {
    margin-bottom: 4px;
}

.docs__steps-step-paragraph {
    margin-bottom: 4px;
}

.docs__tip {
    margin-top: 40px;
    padding: 16px;
    background-color: colors.$generaptor-brand-bg;
    border-radius: 8px;
    overflow: hidden;
}

.docs__tip-headline {
    display: flex;
    align-items: flex-end;
    margin-bottom: 16px;
    font-size: 16px;
    line-height: 19px;
    font-weight: 700;
    color: colors.$generaptor-brand-dark;
}

.docs__tip-headline-icon {
    display: flex;
    margin-right: 8px;
}

.docs__tip-text {
    padding-left: 32px;
    max-width: 700px;
    font-size: 14px;
    line-height: 1.3;
    color: colors.$grey;
}
