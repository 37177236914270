



























































































































@use "@/styles/mixins/breakpoint" as *;
@use "@/styles/variables/colors";
@use "@/styles/variables/grid";

.petitions-list__headline {
    color: colors.$generaptor-brand;
    margin-bottom: 24px;
    letter-spacing: -0.03em;

    @include mobile {
        font-size: 28px;
        line-height: 1.3;
        letter-spacing: -0.02em;
    }
}

.petitions-list__list {
    margin-bottom: 40px;

    @include mobile {
        margin-bottom: 58px;
    }
}

.petitions-list__item {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid colors.$divider;
    padding: 8px 0;

    @include mobile {
        flex-direction: column;
        justify-content: flex-start;
        padding: 16px 0;
    }

    &:first-child {
        border-top: 1px solid colors.$divider;
    }
}

.petitions-list__text {
    position: relative;
    padding-left: 32px;

    @include mobile {
        margin-bottom: 24px;
    }

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 21px;
        height: 21px;
        background-image: url("@/images/icon-doc.svg");
    }
}

.petitions-list__text-title {
    margin-bottom: 10px;
    font-size: 16px;
    line-height: 1.4;
    font-weight: 700;
    color: colors.$secondary-grey;
}

.petitions-list__text-descriptor {
    font-size: 14px;
    line-height: 1.2;
    color: colors.$grey;
}

.petitions-list__item-button-wrapper {
    align-self: center;

    @include mobile {
        padding-left: 32px;
        align-self: flex-start;
    }

    @include desktop {
        margin-left: grid.$space-3;
    }
}

.petitions-list__item-button {
    @include mobile {
        display: none !important;
    }

    &--mobile {
        display: none !important;

        @include mobile {
            display: flex !important;
        }
    }
}

.modal .petitions-list__download-button-wrapper {
    @include mobile {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 16px;
        background-color: colors.$white;
        box-shadow: 0 -9px 15px rgba(178 181 190 / 29%);

        .button {
            width: 100%;
            justify-content: center;
        }
    }
}
