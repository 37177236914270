




































































































































































































@use "@/styles/variables/colors";
@use "@/styles/variables/grid";
@use "@/styles/variables/typography";
@use "@/styles/mixins/breakpoint";

.signin-form {
    margin: 0 auto;
}

.signin-form__logo {
    @include breakpoint.mobile {
        display: none;
    }

    svg {
        display: block;
    }
}

.signin-form__screen {
    display: flex;
    flex-direction: column;
    margin-top: grid.$space-3;
}

.signin-form__cta,
.signin-form__error {
    font-size: typography.$font-size-bigger;
    font-weight: 700;
    line-height: 1.2;
    color: colors.$grey-7;
}

.signin-form__error {
    color: colors.$error;
}

.signin-form__cta-details {
    color: colors.$grey-5;

    .signin-form__cta + & {
        margin-top: grid.$space-1;
    }
}

.signin-form__email-input {
    position: relative;

    .signin-form__cta + &,
    .signin-form__cta-details + & {
        margin-top: grid.$space-3;
    }

    .form-field__label {
        display: none;
    }

    .form-field__helper-wrapper {
        position: absolute;
        padding-top: 0;
    }

    .helper__text--error {
        margin-top: 0;
    }

    .form-field__icon-invalid-wrapper {
        display: none;
    }
}

.signin-form__submit {
    .signin-form__email-input + & {
        margin-top: grid.$space-4;
    }
}

.signin-form__cancel {
    align-self: center;
    margin-top: grid.$space-3 + grid.$space-05;
    border-bottom-style: solid;
}

.signin-form__footer {
    margin-top: grid.$space-5 + grid.$space-05;
    font-size: typography.$font-size-small;
    color: colors.$secondary-grey-light;
}

.signin-form__retry {
    .signin-form__error + & {
        margin-top: grid.$space-3;

        @include breakpoint.desktop {
            margin-top: grid.$space-5 + grid.$space-05;
        }
    }
}

.signin-form__screen--input {
    @include breakpoint.desktop {
        margin-top: grid.$space-5 + grid.$space-05;
    }
}

.signin-form__screen--success {
    .signin-form__status-pic {
        margin-top: grid.$space-2;
        margin-bottom: grid.$space-3;

        @include breakpoint.desktop {
            margin-top: grid.$space-4;
        }
    }

    .signin-form__footer {
        display: none;    // temp
        align-self: center;
    }
}

.signin-form__screen--failure {
    .signin-form__status-pic {
        margin-bottom: grid.$space-5 + grid.$space-05;

        @include breakpoint.desktop {
            margin-bottom: grid.$space-3;
        }
    }
}

.modal .signin-form {
    @include breakpoint.desktop {
        max-width: 345px;
    }
}
