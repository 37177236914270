




























































































































@use "@/styles/mixins/hover" as *;
@use "@/styles/variables/typography";
@use "@/styles/variables/colors";

.button {
    position: relative;
    display: inline-flex;
    overflow: hidden;
    box-sizing: border-box;
    max-width: 100%;
    height: 50px;
    padding: 0 18px;
    background: transparent;
    border: 1px solid transparent;
    border-radius: 4px;
    font-family: typography.$font-family;
    font-size: 18px;
    font-weight: bold;
    line-height: 48px;
    white-space: nowrap;
    text-decoration: none;
    text-overflow: ellipsis;
    cursor: pointer;
    transition: background-color 0.15s ease, border-color 0.15s ease,
        box-shadow 0.15s ease, color 0.15s ease, fill 0.15s ease;
    flex-shrink: 0;
    appearance: none;

    &--primary {
        --text-color: #{colors.$white};
        --bg-color: #{colors.$accent};
        --bg-color-hover: #{colors.$accent-light};
        --bg-color-active: #{colors.$accent-dark};

        color: var(--text-color);
        background-color: var(--bg-color);

        @include hover {
            background-color: var(--bg-color-hover);
        }

        &:active {
            background-color: var(--bg-color-active);
        }
    }

    &--secondary {
        --text-color: #{colors.$accent};
        --text-color-active: #{colors.$white};
        --border-color: #{colors.$accent};

        color: var(--text-color);
        border-color: var(--border-color);

        @include hover {
            color: var(--text-color-active);
            background-color: var(--border-color);
        }

        &:active {
            color: var(--text-color-active);
            background-color: var(--border-color);
        }
    }

    &--pending {
        pointer-events: none;
    }

    &--full-width {
        width: 100%;
        justify-content: center;
    }
}

.button:disabled,
.button--disabled {
    pointer-events: none;
}

.button--primary:disabled,
.button--primary.button--disabled {
    background-color: colors.$secondary-grey-light;
}

.button--secondary:disabled,
.button--secondary.button--disabled {
    opacity: 0.5;
}

.button__content {
    display: inline-flex;
    align-items: center;

    &--pending {
        visibility: hidden;
    }
}

.button__icon:not(:empty) {
    display: flex;
    margin-right: 8px;
}

.button__spinner {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
