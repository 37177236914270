


































































































































































































































































































































































































































































































































































































































































































































































































































































































@use "@/styles/variables/grid";

.formular__fields {
    .form-field {
        &:not(:last-child) {
            margin-bottom: grid.$space-3;
        }
    }
}

.slide-fade-enter-active,
.slide-fade-leave-active {
    transition: all 0.4s ease;
}

.slide-fade-enter,
.slide-fade-leave-to {
    transform: translateY(10px);
    opacity: 0;
}
