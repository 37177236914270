@use "./variables/colors";
@use "./variables/typography";
@use "./mixins/breakpoint" as *;

*,
*::before,
*::after {
    box-sizing: inherit;
}

html {
    box-sizing: border-box;
    background-color: colors.$white;
    color: colors.$text;
    font-family: typography.$font-family, sans-serif;
    font-size: 16px;
    line-height: 1.444;
    height: 100%;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    text-size-adjust: 100%;
}

body {
    height: 100%;

    &.modal-open {
        overflow: hidden;
    }
}

a {
    color: colors.$accent;

    &:hover {
        color: colors.$accent-light;
    }

    &:active {
        color: colors.$accent-dark;
    }
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0;
}

h1,
.h1 {
    font-size: 36px;
    font-weight: 700;
    line-height: 1.2;
    margin-bottom: 24px;

    @include breakpoint(md) {
        font-size: 28px;
        line-height: 1.3;
    }

    @include breakpoint(sm) {
        margin-bottom: 16px;
    }
}

ol,
ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

address {
    font-style: normal;
}

input[type="number"] {
    appearance: textfield;
}

input[type="search"] {
    appearance: textfield;

    &::-webkit-search-decoration,
    &::-webkit-search-cancel-button,
    &::-webkit-search-results-button,
    &::-webkit-search-results-decoration {
        display: none;
    }

    &::-ms-clear,
    &::-ms-reveal {
        display: none;
        width: 0;
        height: 0;
    }
}

fieldset {
    margin: 0;
    padding: 0;
    border: 0;
}
