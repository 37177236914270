@use "./mixins/breakpoint" as *;

.container {
    width: 100%;
    max-width: 1090px;
    margin-right: auto;
    margin-left: auto;
    padding: 0 30px;

    @include breakpoint(sm) {
        padding: 0 16px;
    }
}

.container__grid {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-gap: 16px;

    @include breakpoint(md) {
        display: block;
    }
}
