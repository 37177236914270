


































@use "@/styles/variables/colors";

.validation-errors {
    font-size: 16px;
    font-weight: 700;
    color: colors.$danger;
    margin-bottom: 16px;
}

.validation-errors__error {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
}

.validation-errors__icon {
    margin-right: 8px;
}
